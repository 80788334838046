import React from 'react'
import ParticleBackground from '../../components/particle-background/background';
import Photo1 from "../../images/Photo1.jpeg"
import Photo2 from "../../images/Photo2.jpeg"
import Photo3 from "../../images/Photo3.jpeg"
import Photo4 from "../../images/Photo4.jpeg"
import Photo5 from "../../images/Photo5.jpeg"
import Photo6 from "../../images/Photo6.jpeg"
import Photo7 from "../../images/Photo7.jpeg"
import "./photography.css"

function Card(props) {
  return (
      <div style={{
          ...styles.card,
          ...styles[props.size]
      }}>
        <img className='fit-image' src={props.image} />
      </div>
  )
}

const styles = {
  card: {
      margin: '15px 10px',
      padding: 0,
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'center'
  },
  small: {
      gridRowEnd: 'span 26'
  },
  medium: {
      gridRowEnd: 'span 33'
  },
  large: {
      gridRowEnd: 'span 45'
  }
}

const containerStyles = {
  pin_container: {
      margin: 0,
      padding: 0,
      width: '80vw',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 250px)',
      gridAutoRows: '10px',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      justifyContent: 'center',
      marginTop: '60px',
  }
}

const Photography = () => {
  return (
    <div className='particle-container-background'>
      <ParticleBackground></ParticleBackground>
      <div className='pin-container'>
        <Card size="large" image={Photo1} />
        <Card size="small" image={Photo3} />
        <Card size="large" image={Photo2}/>
        <Card size="small" image={Photo4} />
        <Card size="large" image={Photo6}/>
        <Card size="small" image={Photo5} />
        <Card size="small" image={Photo7} />
      </div>
    </div>
  )
}

export default Photography