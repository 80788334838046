import styled from 'styled-components/macro'

export const BlogContainer = styled.div`
  font-family: 'Anonymous Pro', monospace;
  padding-top: 50px;
  height: 100vh;
`

export const TimelineContainer = styled.div`
  /* background: #212121; */
  margin: 20px auto;
  padding: 20px;
`;

// Card Container
export const Card = styled.div`
  position: relative;
  max-width: 400px;
  ${({ isOdd }) => (isOdd ? 'padding: 30px 0 30px 30px;' : 'padding: 30px 30px 30px 0;')}

  &::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid orangered;
    ${({ isOdd }) => (isOdd
    ? 'left: 0; top: -4.5px; bottom: -4.5px; border-width: 5px 0 5px 5px; border-radius: 50px 0 0 50px;'
    : 'right: 0; top: 0; bottom: 0; border-width: 5px 5px 5px 0; border-radius: 0 50px 50px 0;')}
  }

  &:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
  }

  &:last-child {
    &:nth-child(odd)::before {
      border-bottom: 0;
      border-bottom-left-radius: 0;
    }
    &:nth-child(even)::before {
      border-bottom: 0;
      border-bottom-right-radius: 0;
    }
  }

  @media only screen and (max-width: 400px) {
    &:nth-child(odd)::before,
    &:nth-child(even)::before {
      top: -5px;
      bottom: -5px;
    }
  }
`;

// Information about the timeline
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFF8E3;
  color: #503C3C;
  border-radius: 10px;
  padding: 10px;
`;

// Title of the card
export const Title = styled.h3`
  color: orangered;
  position: relative;
  padding-bottom: 5px;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 999px;
    border: 3px solid orangered;
    ${({ isOdd }) => (isOdd ? 'left: -45px;' : 'right: -45px;')}
  }
`;



