import styled from "styled-components/macro"
import { Link } from "react-router-dom";

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top-left-radius: 110px;  /* 100px of height + 10px of border */
  border-top-right-radius: 110px;
  border-bottom-left-radius: 110px;  /* 100px of height + 10px of border */
  border-bottom-right-radius: 110px;
  background-color: ${props => (props.isScrolled ? 'hsla(45, 17%, 95%, .5);' : 'transparent')};
  transition: background-color 0.3s ease;
  backdrop-filter: ${props => (props.isScrolled ? 'blur(20px) saturate(1.7)' : null)};
  height: 40px;
  font-family: 'Courgette', cursive;
  font-weight: bold;
  opacity: 0.7;
  position: fixed;
  width: 60%;
  margin-top: 20px;
  z-index: 2;
  @media (max-width: 768px) {
    width: 75%;
  }
`

export const NavLink = styled(Link)`
  padding: 5px;
  border-radius: 100px;
  width: 15%;
  text-align: center;
  text-decoration: none;
  background-color: ${props => (props.isActive ? 'white' : 'transparent')};
  color: ${props => (props.isActive ? 'black' : 'white')};
  opacity: ${props => (props.isActive ? '1' : '0.8')};
  font-weight: ${props => (props.isActive ? 'bold' : 'bold')};
  @media (max-width: 768px) {
    width: 17%;
  }
  &:hover {
    background-color: #fff  !important;
    transition: 0.4s;
    cursor: pointer;
    color: black;
    font-weight: bold;
  }
`;