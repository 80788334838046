import React from 'react'
import { BlogContainer, TimelineContainer, Card, Info, Title } from './styles/Blog'
import { Link } from 'react-router-dom'

export const Blog = () => {
  return (
    <BlogContainer>
      <TimelineContainer>
      <Card isOdd>
        <Link to="get-set-go" style={{ textDecoration: 'none' }}>
        <Info>
          <Title isOdd>Get set go | December 19, 2023</Title>
          <p>
            Hey there! I thought, let's divide the blog into little chapters. This is by no means a new year resolution. It is just me writing because I love to write. Click on me, and start reading, why don't ya?
          </p>
        </Info>
        </Link>
      </Card>
      <Card isEven>
      <Link to="scoping-the-landscape" style={{ textDecoration: 'none' }}>
        <Info>
          <Title isEven>Chapter 1 | Scoping the land Scape | December 23, 2023</Title>
          <p>
          Hi! This is Aadya. I am an intern at a landscape architecture firm. Yes a घासपुस architect. A फूल पत्ती architect. You know like the घासपुस का डॉक्टर ? The botanist from Chupke Chupke! Dharmendra's Chupke Chupke not Shahid Kapoor's! Come on! Let us take a moment here to appreciate this greatest movie of all time. 
          </p>
        </Info>
      </Link>
      </Card>
      {/* Add more Card components as needed */}
    </TimelineContainer>
    </BlogContainer>
  )
}

