import React from "react";
import Navbar from "./components/navbar/navbar";
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/home/home";
import {Blog} from "./pages/blogs/blog";
import {About} from "./pages/about/about";
import { Container } from "./styles/App";
import { Error } from "./pages/error/Error"
import Photography from "./pages/photography/photography";
import Portfolio from "./pages/portfolio/portfolio";
import FirstBlog from "./pages/first/FirstBlog";
import SecondBlog from "./pages/second/SecondBlog";


function App() {
  return (
    <Container>
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/photography" element={<Photography />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/blogs/get-set-go" element={<FirstBlog />} />
      <Route path="/blogs/scoping-the-landscape" element={<SecondBlog />} />
      <Route path="*" element={<Error />} />
    </Routes>
    </Container>
  );
}

export default App;
