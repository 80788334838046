import React from "react";
import Particles from "react-tsparticles"; 
import { loadFull } from "tsparticles"; 
import particleConfig from "../../config/particle-config";

export default function ParticleBackground() {
  const particlesInit = async (main) => {
    await loadFull(main); 
  }; 
  const particlesLoaded = (container) => {}; 
  return (
    <Particles
      height="100vh"
      id="tsparticles" 
      style={{position: "fixed", zIndex: -1}}
      init={particlesInit} 
      loaded={particlesLoaded} 
      options={particleConfig}
    /> 
  );
}