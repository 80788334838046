import React from 'react'
import P1 from "../../images/P1.png"
import P2 from "../../images/P2.png"
import P3 from "../../images/P3.png"
import P4 from "../../images/P4.png"
import P5 from "../../images/P5.png"
import P6 from "../../images/P6.png"
import P7 from "../../images/P7.png"
import P8 from "../../images/P8.png"
import P9 from "../../images/P9.png"
import P10 from "../../images/P10.png"
import P11 from "../../images/P11.png"
import P12 from "../../images/P12.png"
import P13 from "../../images/P13.png"
import P14 from "../../images/P14.png"
import P15 from "../../images/P15.png"
import P16 from "../../images/P16.png"
import P17 from "../../images/P17.png"
import P18 from "../../images/P18.png"
import P19 from "../../images/P19.png"
import "./portfolio.css"

const Portfolio = () => {
  return (
    <div className='portfolio-container'>
      <img className='portfolio-img' src={P1} />
      <img className='portfolio-img' src={P2} />
      <img className='portfolio-img' src={P3} />
      <img className='portfolio-img' src={P4} />
      <img className='portfolio-img' src={P5} />
      <img className='portfolio-img' src={P6} />
      <img className='portfolio-img' src={P7} />
      <img className='portfolio-img' src={P8} />
      <img className='portfolio-img' src={P9} />
      <img className='portfolio-img' src={P10} />
      <img className='portfolio-img' src={P11} />
      <img className='portfolio-img' src={P12} />
      <img className='portfolio-img' src={P13} />
      <img className='portfolio-img' src={P14} />
      <img className='portfolio-img' src={P15} />
      <img className='portfolio-img' src={P16} />
      <img className='portfolio-img' src={P17} />
      <img className='portfolio-img' src={P18} />
      <img className='portfolio-img' src={P19} />
    </div>
  )
}

export default Portfolio