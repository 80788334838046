import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  color: #fff;
  z-index: 1;
  /* font-family: 'Anonymous Pro', monospace; */
  /* font-family: 'Courgette', cursive; */
  font-family: 'Rubik Doodle Shadow';
  font-size: 64px;
  /* font-weight: bold; */
  @media (max-width: 768px) {
    font-size: 48px;
  }
  @media (max-width: 540px) {
    font-size: 36px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
  }
`

export const BlogLink = styled(Link)`
  text-decoration: none;
  color: white;
  cursor: pointer;
  z-index: 1;
  font-size: 18px;
  background-color: #fff;
  border-radius: 20px;
  width: 30px;
  margin-top: 10px;
  padding-left: 8px;
  padding-top: 5px;
  color: #000;
  opacity: 0.5;
`