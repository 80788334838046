import React from 'react'
import "./FirstBlog.css"

const FirstBlog = () => {
  return (
    <div className='container'>
        <p>Hi! This is Aadya. A budding architect. An architect in the making. An almost cooked architect. A ready to eat - just add water architect. A ... you get the point.</p>
        <p>I am writing this blog to just be myself. If you have any issues with the writing - no offence taken here.</p>
        <p>As you can see, I am extremely new at this. I am 1000 % sure that I am going to come across this many years later and hide myself under a blanket. But I have to start somewhere, right?</p>
        <p>You see, in architecture we use instruments like T scale and set squares. But in life- nothing is set or scaled... unexpected things keep happening - like this blog. Hence the name Life with a T square... </p>
        <p>PS: this blog will be cheesy, motivational, sarcastic and full of good jokes ( or dad jokes as they call it nowadays), so brace yourself. Warning you...</p>
    </div>
  )
}

export default FirstBlog