import React from 'react'
import ParticleBackground from '../../components/particle-background/background'
import Aadya from "../../images/Aadya.jpeg"
import { AboutContainer, ContentContainer, ImageContainer } from '../about/styles/About'

export const About = () => {
  return (
    <AboutContainer>
      <ParticleBackground></ParticleBackground>
      <ImageContainer src={Aadya} />
      <ContentContainer>
        <div>
        <p>
          Hey there! 👋 I'm Aadya Pedanekar, an architect. 
          As I traverse the delicate balance between lines and curves in the 
          world of architecture, here's an online diary of my journey. 
          Hmm, maybe I can add a vlog someday. Let's see...
        </p>
        <br />
        <p>
          I like photography, and needless to say, on the tab titled "photography", you can see all the photos I have taken.
          This blog was an impulsive choice, really. I thought, why not? If you want to get in touch, you can find me on LinkedIn!
        </p>
        </div>
      </ContentContainer>
    </AboutContainer>
  )
}

