import React from 'react'
import "../first/FirstBlog.css"

const FirstBlog = () => {
  return (
    <div className='container'>
        <p>So coming back to landscape architecture. It is not at all like architecture. You need an understanding of architecture, how the internal spaces of a building are working, and how inside and outside speak with each other, etc. To tell explain in simple terms-  imagine that you are going to your house, you were stuck in the grey traffic and you come back tired from work/ college. You get inside your society like the Hulk. The noise level suddenly drops, it had drizzled just a few minutes ago, leaving the whole city full of puddles but your society full of fragrance. The fragrance of wet soil. You can see lush green trees around and bright flowers with butterflies hovering around. You enter your building through a beautifully designed entrance and go to your house. Now it has started raining again and so you are sitting at the window, looking outside. You can see the sky, trees, plants, hear the birds, and let your hair blow with the air while you enjoy a cup of tea.  Although if the winds are strong, there will be dust and rain in your tea, and hair in your mouth. But, we are considering the best case scenario, the dream case scenario so... everything is perfect. This is what landscape architects design. The peacefulness. Of course this is just me scratching the surface of it. But yes, its pretty awesome.</p>
        <p>So moral of the story please do not hesitate to hire ( and pay) a landscape architect. Its like getting your source of mental peace designed. Its amazing!</p>
        <p>I am having second thoughts. I should have gone into advertising....</p>
    </div>
  )
}

export default FirstBlog