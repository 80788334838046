import styled from "styled-components/macro";

export const AboutContainer = styled.div`
  margin-top: calc(40px + 3rem);
  height: calc(100vh - 40px - 5rem);
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: justify;
  text-align-last: center;
  color: #fff;
  font-family: 'Cabin', sans-serif;
  font-size: 18px;
  /* font-weight: bold; */
  @media (max-width: 1080px) {
    padding-bottom: 20px;
    font-size: 16px;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    flex-direction: column;
  }
  @media (max-width: 540px) {
    font-size: 14px;
    flex-direction: column;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    flex-direction: column;
  }
`

export const ImageContainer = styled.img`
  width: auto;
  animation: swing ease-out 5s forwards;
  z-index: 1;
  padding: 0pt 15pt;
  @media (max-width: 1080px) {
    height: auto;
    width: 70%;
    padding: 15pt 0pt;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    width: 70%;
  }
  @media (max-width: 540px) {
    font-size: 14px;
    width: 85%;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    width: 100%;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  @media (max-width: 1080px) {
    padding-bottom: 20px;
  }
`